/*
 * @Author: xingwanjiang
 * @Date: 2022-03-14 13:20:47
 * @LastEditors: WangPei
 * @LastEditTime: 2024-06-14 13:50:19
 * @Description: 接口api
 */
import request from '../utils/request'

/**
 * @description: 获取医院选择树
 * @param {*}
 * @return {*}
 */
export function fetchHospInfo(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/queryAreaAndEquipInfoForMatchineRoom',
    method: 'post',
    data: data
  })
}
/**
 * @description: 机房-查询设备信息
 * @param {*}
 * @return {*}
 */
export function fetchEquipInfo(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/queryMachineRoomEquipInfo',
    method: 'post',
    data: data
  })
}
/**
 * @description: 查询设备环境信息
 * @param {*}
 * @return {*}
 */
export function fetchEquipEnvInfo(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/queryMachineRoomEnvInfo',
    method: 'post',
    data: data
  })
}
/**
 * @description: 用户登录
 * @param {*}
 * @return {*}
 */
export function userLogin(data) {
  return request({
    url: '/newyctechUser/user/userLogin',
    method: 'post',
    data: data 
  })
}
/**
 * @description: 进出信息登记
 * @param {*}
 * @return {*}
 */
export function recordOfAccess(data) {
  return request({
    url:'/newyctechManage/machineRoom/findByBid',
    method:'post',
    params:data
  })
}
/**
 * @description: 获取设备列表
 * @param {*}
 * @return {*}
 */
export function getEquipList(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/queryBmEquipMaintenance',
    method: 'post',
    data: data
  })
}
/**
 * @description:新增设备维修信息
 * @param {*}
 * @return {*}
 */
export function addRoomManageList(params) {
  return request({
    url: '/newyctechManage/machineRoom/saveBmMachineRoom',
    method: 'post',
    data: params
  })
}
/**
 * @description: 统计设备检查人次
 * @param {*}
 * @return {*}
 */
export function countEquipPersonNum(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/countMachineRoomEquipCheckPersonNum',
    method: 'post',
    data: data
  })
}
/**
 * @description: 机房-使用时长（近一周）
 * @param {*}
 * @return {*}
 */
export function countEquipUseTime(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/countMachineRoomEquipCheckPersonTime',
    method: 'post',
    data: data
  })
}
export function countEquipUseTimes(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/countMachineRoomEquipCheckTimeVTwo',
    method: 'post',
    data: data
  })
}

/**
 * @description: 机房-检查序列明细（近一周 ）-后面顾总要求新增的两个图表之一
 * @return {*}
 */
export function countWorkTimes(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/machineRoomCountCheckSequence',
    method: 'post',
    data: data
  })
}
/**
 * @description:开关机时间趋势 
 * @return {*}
 */
export function countCloseTimes(data) {
  return request({
    url: '/newyctechManage/machineRoomEquipInfo/switchTimeCountCheckSequence',
    method: 'post',
    data: data
  })
}
/**
 * @description: 		查询所有设备信息(设备信息轮播条)
 * @param {*}
 * @return {*}
 */
export function equipSelectAll(data) {
  return request({
    url: `/newyctechManage/interface/selectAllByEquip?hospitalBid=${data}`,
    method: 'post'
  })
}
export function getFindEquip(data) {
  return request({
    url: `/newyctechManage/equipInfo/findByBid?bid=${data}`,
    method: 'post'
  })
}