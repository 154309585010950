/*
 * @Author: xingwanjiang
 * @Date: 2021-12-22 09:36:32
 * @LastEditors: WangPei
 * @LastEditTime: 2024-06-28 14:34:41
 * @Description:
 */

import axios from 'axios'
import router from '@/router'
import {
  MessageBox,
  Message
} from 'element-ui'
import store from '@/store'
// import {
//   getToken
// } from '@/utils/auth'

// const baseURL = 'http://192.168.1.17:8009'
// const baseURL = 'http://192.168.11.124:8009'  //开发环境
// const baseURL = 'http://192.168.13.30:8009'
//  const baseURL = 'http://192.168.1.47:8009'
// const baseURL = 'http://36.137.215.244:7009'
const baseURL = 'https://yyglpt.equipcard.newyctech.com/api'  // 线上环境
// const baseURL = 'http://10.10.171.130:8009'  // 江大环境
// axios.defaults.headers.post['Content-Type'] = 'application/json'
// create an axios instance
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      // config.headers['Authorization'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data
    // if the custom code is not 20000, it is judged as an error.
    if (res.code && res.code !== 200) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login
        MessageBox.confirm('您的登录信息已经过期，请重新登录', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    const data = error.response.status
    if (data === 401) {
      Message({
        message: 'toke过期,请退出重新登录',
        type: 'error',
        duration: 1000
      })
      store.dispatch('user/resetToken')
      router.push({
        path: '/'
      })
    } else {
      // const code = error.data.code
      Message({
        message: error.response.message1 || '系统错误',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return Promise.reject(error)
  }
)

export default service